import React from "react";

const Partenaires = () => {
  return (
    <>
      <div className="contact">
        <h2 className="container_QuiSuisJe_title">Nos partenaires</h2>
        <div className="container_QuiSuisJe">
         
          <p className="contact_texte">
            <br />
            <br />
            </p>
          
       
          
        </div>{" "}
      </div>
    </>
  );
};

export default Partenaires;
