
import Header from "../composent/Head";
import Foot from "../composent/Foot";
import Card from "../composent/Card";




export default function ProjectsPage() {
  return (
    <>
        <link rel="canonical" href="https://marsaudolivierdev.fr/projets" />
    <Header />
    <Card />


    <Foot />


   
    </>
  );
}
