
import Header from "../composent/Head";
import Foot from "../composent/Foot";
import Partenaires from "../composent/Partenaires";







export default function PartenairesPage() {
  return (
    <>
    <link rel="canonical" href="https://marsaudolivierdev.fr/partenaires" />
    <Header />
    <Partenaires />
  

    <Foot />


   
    </>
  );
}
