
import Header from "../composent/Head";
import Foot from "../composent/Foot";
import Mentions from "../composent/Mentions";





export default function MentionsPage() {
  return (
    <>
    <Header />
    <img
              src="assets/images/logo.svg"
              alt="logo"
              className="errror"
            />
    <h2 className="errror_text">404 Ressources introuvables</h2>


    <Foot />


   
    </>
  );
}
