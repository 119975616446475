
import Header from "../composent/Head";
import Foot from "../composent/Foot";
import Mentions from "../composent/Mentions";





export default function MentionsPage() {
  return (
    <>
    <link rel="canonical" href="https://marsaudolivierdev.fr/mentions" />
    <Header />
    <Mentions />

    <Foot />


   
    </>
  );
}
